html,
body,
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

::-webkit-input-placeholder {
  opacity: 1 !important;
  color: #8094ae !important;
}
:-ms-input-placeholder {
  opacity: 1 !important;
  color: #8094ae !important;
}
::-moz-placeholder {
  opacity: 1 !important;
  color: #8094ae !important;
}
:-moz-placeholder {
  opacity: 1 !important;
  color: #8094ae !important;
}
